// src/directive/loadmore.js
export default {
  inserted(el, binding) {
    const SELECT_WRAP_SELECTOR = '.el-select-dropdown .el-select-dropdown__wrap';
    const SELECT_LIST_SELECTOR = '.el-select-dropdown .el-select-dropdown__list';

    let selectWrapEl = null;
    let selectListEl = null;
    selectWrapEl = el.querySelector(SELECT_WRAP_SELECTOR);
    selectListEl = el.querySelector(SELECT_LIST_SELECTOR);
    let observer = null;

    function handleScroll() {
      console.log("handleScroll")
      if (selectWrapEl.scrollTop + selectWrapEl.clientHeight >= selectListEl.clientHeight - 10) {
        selectWrapEl.removeEventListener('scroll', handleScroll);

        if (typeof binding.value === 'function') {
          binding.value();
        }
      }
    }

    function observeDOMChanges() {
      observer = new MutationObserver(mutations => {
        console.log("mutations: ", mutations)
      });

      observer.observe(selectListEl, { childList: true, subtree: true });
    }

    observeDOMChanges();

    el.__loadmore__ = {
      destroy() {
        if (selectWrapEl) {
          selectWrapEl.removeEventListener('scroll', handleScroll);
        }
        if (observer) {
          observer.disconnect();
        }
      }
    };
  },
  unbind(el) {
    if (el.__loadmore__ && typeof el.__loadmore__.destroy === 'function') {
      el.__loadmore__.destroy();
    }
  }
};