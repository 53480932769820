<template>
  <el-dialog
    :title="type == 'add' ? '新增简称' : '修改简称'"
    :close-on-click-modal="false"
    :visible.sync="show"
    append-to-body
    width="500px"
    center
  >
    <div class="dialog-content" v-loading="loading">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
        <el-form-item label="学校全称">
          <span>{{schoolName}}</span>
        </el-form-item>
        <el-form-item label="已有简称" v-show="schoolShortList.length > 0">
          <div>
            <el-tag v-for="(item, index) in schoolShortList" :key="index" class="mR10">{{item}}</el-tag>
          </div>
        </el-form-item>
        <el-form-item label="新增简称" prop="shortName">
          <el-input
            placeholder="请输入学校简称，多个的话用“,”隔开！"
            class="self-input"
            type="textarea"
            :rows="3"
            v-model="ruleForm.shortName"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取 消</el-button>
      <el-button type="primary" :loading="saveLoading" @click="handleConfirmSave"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  apiAddSchoolShortName,
  apiGetSchoolDetail,
} from "@/api/sales";
export default {
  data() {
    return {
      show: false,
      type: "add",
      saveLoading: false,
      schoolShortList: [],
      schoolName: null,
      loading: false,
      ruleForm: {
        shortName: null, // 学校简称
        id: null,
      },
      rules: {
        shortName: [
          { required: true, message: "学校简称不能为空！", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
                if(/^(,|，)/.test(value) || /(,|，)$/.test(value)){
                    callback(new Error("学校简称开头和结尾不能包含逗号！"))
                    return
                }
                callback()
            },
            trigger: "blur"
          }
        ],
      },
    };
  },
  props: {
    addSchoolKeywordParams: {
      type: Object,
      default: () => {
        return {
          show: false,
          type: "add",
          id: null,
        };
      },
    },
  },
  watch: {
    "addSchoolKeywordParams.show"() {
      const { show, type, id } = this.addSchoolKeywordParams;
      this.show = show;
      this.type = type;
      this.ruleForm.id = id;
    },
    show() {
      if (this.show) {
        this.getSchoolDetail();
      } else {
        this.$refs["ruleForm"].resetFields();
        this.$emit("dialogDataChange", { type: "close" });
      }
    },
  },
  mounted() {},

  methods: {
    /* 获取订单详情 */
    async getSchoolDetail() {
      try {
        this.loading = true 
        let {  schoolName, shortName } = await apiGetSchoolDetail({
          id: this.ruleForm.id,
        });
        this.loading = false 
        this.schoolName = schoolName
        this.schoolShortList = shortName ? shortName.split(",") : []
      } catch (err) {
        this.loading = false 
        let msg = err.msg ? err.msg : "获取学校详情失败！";
        this.msgError(msg);
        console.error("getSchoolDetail err: ", err);
      }
    },
    /* 保存数据 */
    handleConfirmSave() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          try {
            this.saveLoading = true;
            const shortName = this.ruleForm.shortName.replace(/，/g, ',')
            const resFlag = await apiAddSchoolShortName( { ...this.ruleForm, shortName });
            this.saveLoading = false;
            this.show = false;
            if(resFlag){
              this.msgSuccess("保存成功！");
              this.$emit("dialogDataChange", { type: "save" });
            } else {
              this.msgError("保存发生异常！")
            }
          } catch (err) {
            this.saveLoading = false;
            let msg = err.msg ? err.msg : "保存失败！";
            this.msgError(msg);
            console.error("handleConfirmSave err: ", err);
          }
        } else {
          console.log("error submit!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-content {
  max-height: calc(76vh - 140px);
  overflow-y: auto;
}
.self-input {
  width: 350px;
}
</style>
